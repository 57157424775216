@import "/src/styles/variables.scss";

.additionalTextLink {
	transition: 0.3s;
	padding: 0 !important;

	&::before {
		display: none;
	}

	text-align: left;
}
