@import "/src/styles/variables.scss";

.buttonContainer {
	text-align: center;
}

.button {
	width: 100%;
}

.privacyText {
	@include tagStyles();
}

.doubleInputWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: $flexGap16;
}

.doubleInput {
	flex: 1;
	min-width: 200px;
}

.formContainer {
	display: flex;
	flex-direction: column;
	gap: $flexGap8;
}
