@import "/src/styles/variables.scss";

.wrapper {
	width: 100%;
	background-color: $grayScaleLvl5;
	display: flex;
	min-height: 300px;
	align-items: center;
	justify-content: center;
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}

.hide {
	opacity: 0;
	pointer-events: none;
}

.container {
	color: black;
	width: 100%;
	padding: 4rem 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.title {
	font-size: 36px;
	font-weight: 600;
	margin-bottom: 20px;
	text-align: center;
}

.message {
	font-size: 24px;
	font-weight: 400;
	text-align: center;
}

.animation {
	width: 100%;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.rotatingText {
	font-size: 48px;
	font-weight: 600;
	text-transform: uppercase;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
}

.rotatingText span {
	margin-right: 20px;
}
