@import "/src/styles/variables.scss";

.inputContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}

.label {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 8px;
}

.asFile .label {
	cursor: pointer;
	font-size: 16px;
	padding: 20px;
	border: 1px solid $grayScaleLvl4;
	border-radius: $borderRNormal;
	width: 100%;
	height: 27px;
	display: flex;
	align-items: center;
	transition: 0.3s;
}

.asFile .label:hover {
	border: 1px solid $brandColor;
	transition: 0.3s;
}

.input {
	font-size: 16px;
	padding: 20px;
	border: 1px solid $grayScaleLvl4;
	border-radius: $borderRNormal;
	width: 100%;
	height: 27px;

	&:disabled {
		color: $grayScaleLvl3;
		border: 1px $grayScaleLvl3 solid;
		background-color: $grayScaleLvl6;
		cursor: default;
	}
}

.inputContainer input[type="file"] {
	display: none;
}

.inputError {
	font-size: 16px;
	padding: 20px;
	border: 1px solid #ff0000;
	border-radius: $borderRNormal;
	width: 100%;
	height: 27px;
}

.error {
	font-size: 14px;
	color: $alertColorLvl2;
	min-height: calc(2rem - 4px);
	margin-top: 4px;
	bottom: 0;
	left: 0;
}

.ggSoftwareDownload {
	box-sizing: border-box;
	position: relative;
	display: block;
	transform: scale(var(--ggs, 1));
	width: 16px;
	height: 6px;
	border: 2px solid;
	border-top: 0;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	margin-top: 8px;
	margin-left: 0.5rem;
}

.ggSoftwareDownload::after {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	width: 8px;
	height: 8px;
	border-left: 2px solid;
	border-bottom: 2px solid;
	transform: rotate(-45deg);
	left: 2px;
	bottom: 4px;
}

.ggSoftwareDownload::before {
	content: "";
	display: block;
	box-sizing: border-box;
	position: absolute;
	border-radius: 3px;
	width: 2px;
	height: 10px;
	background: currentColor;
	left: 5px;
	bottom: 5px;
}
