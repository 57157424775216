@import "/src/styles/variables.scss";

.error {
	font-size: 14px;
	color: $alertColorLvl2;
	min-height: calc(2rem - 4px);
	margin-top: 4px;
	bottom: 0;
	left: 0;
}
