@import "/src/styles/variables.scss";

// .inputContainer {
// 	display: flex;
// 	flex-direction: column;
// 	width: 100%;

// 	&:last-child {
// 		margin-bottom: 0;
// 	}
// }

// .label {
// 	font-size: 16px;
// 	font-weight: 400;
// 	margin-bottom: 0.5rem;
// }

// .input {
// 	font-size: 16px;
// 	padding: 11px;
// 	width: 100%;
// 	border: 1px solid $grayScaleLvl4;
// 	border-radius: 0.25rem;
// 	appearance: none;
// 	background-color: $white;
// 	transition: all 0.2s ease-in-out;
// 	position: relative;

// 	&:hover,
// 	&:focus {
// 		border-color: #007bff;
// 		outline: none;
// 	}

// 	&:focus {
// 		box-shadow: $boxShadowNormal;
// 		z-index: 1;
// 	}

// 	&:disabled {
// 		opacity: 0.5;
// 	}
// }

// .inputError {
// 	border-color: #ff0000;

// 	&:hover,
// 	&:focus {
// 		border-color: #ff0000;
// 	}

// 	font-size: 16px;
// 	padding: 11px;
// 	border-radius: 0.25rem;
// 	appearance: none;
// 	background-color: $white;
// 	transition: all 0.2s ease-in-out;
// 	position: relative;

// 	&:hover,
// 	&:focus {
// 		outline: none;
// 	}

// 	&:focus {
// 		box-shadow: $boxShadowNormal;
// 		z-index: 1;
// 	}

// 	&:disabled {
// 		opacity: 0.5;
// 	}
// }

// .error {
// 	font-size: 0.875rem;
// 	color: #ff0000;
// 	margin-top: 0.25rem;
// }

.inputContainer {
	position: relative;
}

.label {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 8px;
	display: block;
}

.error {
	font-size: 14px;
	color: $alertColorLvl2;
	margin-top: 4px;
	min-height: calc(2rem - 4px);
	bottom: 0;
	padding: 0;
	left: 0;
}

.customSelect {
	position: relative;
	display: inline-block;
	background: white;
	border-radius: 4px;

	width: 100%;
}

.selectedOption {
	height: 0;
	align-items: center;
	display: flex;
	padding: 20px;
	color: $black;
	border-radius: 4px;
	border: 1px solid $grayScaleLvl4;
	color: black;
	cursor: pointer;

	&.disabled {
		color: $grayScaleLvl3;
		border: 1px $grayScaleLvl3 solid;
		background-color: $grayScaleLvl6;

		&:hover {
			color: $grayScaleLvl3;
			border: 1px $grayScaleLvl3 solid;
			background-color: $grayScaleLvl6;
			cursor: default;
		}
	}

	&:hover {
		background-color: $grayScaleLvl5;
	}

	&.closed.inputError {
		font-size: 16px;
		padding: 20px;
		border: 1px solid #ff0000;
		border-radius: $borderRNormal;
		width: 100%;
		height: 27px;
	}

	&.open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		border-bottom: 1px solid transparent;
		background-color: $grayScaleLvl5;

		&:hover {
			background-color: $grayScaleLvl5;
		}
	}

	&::after {
		content: "";
		position: absolute;
		z-index: 99;
		top: -2.5px;
		right: 10px;
		bottom: 0;
		margin: auto 0;
		color: $black;
		height: 5px;
		width: 5px;
		border-right: 2px solid;
		border-bottom: 2px solid;
		transform: rotate(45deg);
		transition: transform 0.2s;
	}
}

.selectedOptionLabel {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 0;
}

.options {
	border: 1px solid $grayScaleLvl4;
	position: absolute;
	top: 100%;
	left: 0;
	color: black;
	width: 100%;
	z-index: 101;
	text-align: left;
	background-color: $white;
	border-top: none;
	border-radius: 0 0 3px 3px;
	overflow: hidden;
	transition: opacity 0.3s ease;
}

.option {
	padding: 10px;
	cursor: pointer;

	&:hover {
		background-color: $grayScaleLvl5;
	}
}

.selected {
	background-color: $grayScaleLvl5;
}

.enter {
	overflow: hidden;
	opacity: 1;
	max-height: 1px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.enterActive {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 500ms ease-in-out, max-height 500ms ease-in-out;
}

.exit {
	overflow: hidden;
	opacity: 1;
	max-height: 1000px;
	transition: opacity 200ms ease-in-out, max-height 200ms ease-in-out;
}

.exitActive {
	overflow: hidden;
	opacity: 0;
	max-height: 1px;
	transition: opacity 200ms ease-in-out, max-height 200ms;
}
