@import "/src/styles/variables.scss";

.inputContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100px;
	position: relative;
}

.label {
	font-size: 16px;
	font-weight: 600;

	margin-bottom: 8px;
}

.input {
	resize: vertical;
	font-size: 16px;
	padding: 8px;
	border: 1px solid $grayScaleLvl4;
	border-radius: $borderRNormal;
	min-height: 100px;
	&:disabled {
		color: $grayScaleLvl3;
		border: 1px $grayScaleLvl3 solid;
		background-color: $grayScaleLvl6;
		cursor: default;
	}
}

.inputError {
	resize: vertical;
	font-size: 16px;
	padding: 8px;
	border: 1px solid #ff0000;
	border-radius: $borderRNormal;
}
