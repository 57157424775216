@import "/src/styles/variables.scss";

.cardContainer {
	display: flex;
	justify-content: start;
	align-items: center;
	position: relative;
	flex-direction: row-reverse;
	min-height: 100%;
	border-radius: $borderRFull;
	gap: $flexGap24;
	&:hover {
		.image {
			scale: 1.05;
			transition: 0.3s;
		}
	}
}

.swiper-pagination {
	bottom: 40px;
}

.contentContainerMinimal {
	width: 100%;
	position: relative;
	padding-bottom: 40px;
}

.assetContainer {
	display: block;
	height: 0;
	overflow: hidden;
	padding-bottom: 27%;
	position: relative;
	@include flex(2, $flexGap24);
	border-radius: $borderRFull;
}

.smallDesc {
	display: none;
}

.categories {
	color: $brandColorShadeLvl1;

	&:hover {
		color: $brandColorShadeLvl2;
	}
}

.upperTitle {
	display: flex;
	align-items: start;
	flex-direction: column;
	justify-content: space-between;
}

.image {
	height: 100%;
	position: absolute;
	transition: 0.3s;
	width: 100%;
	object-fit: cover;
}

.contentContainer {
	@include flex(2, $flexGap24);
	position: relative;
}

.minimalAsset {
	align-self: start;
	padding-right: 10%;
}

@media only screen and (max-width: $L) {
	.bigDesc {
		display: none;
	}

	.smallDesc {
		display: unset;
	}

	.cardContainer {
		align-items: stretch;

		flex-wrap: wrap;
	}

	.contentContainer {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: $flexGap24;
	}
}

@media screen and (max-width: $M) {
	.upperTitle {
		align-items: start;
	}

	.smallDesc {
		display: none;
	}

	.bigDesc {
		display: unset;
	}

	.cardContainer {
		flex-direction: column;
	}

	.assetContainer {
		padding-bottom: 55%;
	}

	.contentContainer {
		gap: 0;
	}
}
